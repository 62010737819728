import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  customerNationalitySelector,
  showSrPriceSelector,
  showWhsPriceSelector,
} from '../../store/customer/selectors'
import { getFluidFontSize } from '../../style/theme'
import { useTranslation } from 'react-i18next'
import { localesUtils } from '../../libs/locales'

const ProductPrices = styled.div`
  line-height: 1;
  font-family: GilmerBold, sans-serif;
  font-size: ${getFluidFontSize('10px')};
  display: flex;

  &.vertical {
    flex-direction: column;
    align-items: flex-end;
  }

  &.horizontal {
    align-items: center;
  }
`

const WhsPrice = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1ch;
`

const SrPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1ch;
  font-size: 80%;
`

type Props = {
  whsPrice?: number | string | null
  srPrice?: number | string | null
  currency: string
  className?: string
  variant: 'horizontal' | 'vertical'
  wholesaleLabel?: string
  retailLabel?: string
}

export const Price: React.FC<Props> = ({
  className,
  variant,
  whsPrice,
  srPrice,
  currency,
  wholesaleLabel = '',
  retailLabel,
}) => {
  const { t } = useTranslation()
  const showSrPrice = useSelector(showSrPriceSelector)
  const showWhsPrice = useSelector(showWhsPriceSelector)
  const customerNationality = useSelector(customerNationalitySelector)

  return (
    <ProductPrices
      className={['product-prices', className, variant].join(' ')}
      data-testid="product-prices"
    >
      {showWhsPrice && whsPrice?.toString() && (
        <WhsPrice data-testid="product-prices__whsPrice" className="product-prices__whsPrice">
          <span>{wholesaleLabel}</span>
          <span>{localesUtils.formatCurrency(customerNationality, whsPrice, currency)}</span>
        </WhsPrice>
      )}
      {showSrPrice && srPrice?.toString() && (
        <SrPrice data-testid="product-prices__srPrice" className="product-prices__srPrice">
          <span>{retailLabel ? retailLabel : t('Afa.srp')}</span>
          <span>{localesUtils.formatCurrency(customerNationality, srPrice, currency)}</span>
        </SrPrice>
      )}
    </ProductPrices>
  )
}

export default Price

import qs from 'qs'

import app_config from '../config/app/config'
import { emptyTagGroup, showCartWarnings } from '../libs/cart'
import { getIntersessionCustomerId } from '../libs/customer'
import { getIntersessionEventId } from '../libs/event'
import { handleErrors } from '../libs/handleError'
import { getDeviceFromUrl, getRoomFromUrl } from '../libs/url'
import { AppointmentApi, JoinAppointmentResponse } from '../model/appointment'
import { TagGroup } from '../model/cart'
import {
  ContentDigitalEventsAppointmentTilesBackground,
  ContentDigitalEventsBackgrounds,
  SingleBrandPageContents,
} from '../model/content'
import { CustomerType } from '../model/customer'
import { Initiative } from '../model/initiative'
import { AppLangCode } from '../model/model'
import { Product, QueryParams } from '../model/product'
import { StarsResponse } from '../model/stars'

const getHeaders = (authToken: string, lang?: string) => ({
  'Accept-Language': lang ? lang : app_config.langDefault,
  'Room-Name': getRoomFromUrl() || '',
  Authorization: `Bearer ${authToken}`,
})

export function apiLog(error: any, source?: string) {
  const errorToReport =
    error instanceof Error
      ? {
          message: error.message,
          stack: error.stack,
        }
      : {
          message: error.message || error,
          stack: error.stack || error,
        }

  return fetch(`${app_config.apiUrl}/log/error`, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      error: errorToReport,
      room: getRoomFromUrl(),
      device: getDeviceFromUrl(),
      source,
      socketId: window.socketInfo && window.socketInfo.socket && window.socketInfo.socket.id,
      customerId: getIntersessionCustomerId(),
      eventId: getIntersessionEventId(),
    }),
  })
}

export function apiLogin(data: any) {
  return fetch(`${app_config.apiUrl}/login`, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify(
      data.username
        ? { username: data.username, password: btoa(data.password), roomName: getRoomFromUrl() }
        : {
            token: data,
          },
    ),
  })
}

export function apiBrands({
  authToken,
  customerId,
  eventId,
}: {
  authToken: string
  customerId: string
  eventId: string
}) {
  const params = qs.stringify({ customerId, eventId })
  return fetch(`${app_config.apiUrl}/brand?${params}`, {
    headers: getHeaders(authToken),
  })
}

export function apiCustomerList(authToken: string, eventId: string) {
  return fetch(`${app_config.apiUrl}/customer?eventId=${encodeURIComponent(eventId)}`, {
    headers: getHeaders(authToken),
  })
}

export function apiCustomer(
  authToken: string,
  customerId: string,
  eventId: string,
  assortment: string,
) {
  // the call should start only if customerId and eventId are not empty
  return customerId && eventId
    ? fetch(
        `${app_config.apiUrl}/customer/${encodeURIComponent(customerId)}/${encodeURIComponent(
          eventId,
        )}/${encodeURIComponent(assortment)}`,
        {
          headers: getHeaders(authToken),
        },
      )
    : Promise.reject(
        new Error(
          `Missing parameter: ${[!customerId && 'customerId', !eventId && 'eventId']
            .filter(x => !!x)
            .join(', ')}`,
        ),
      )
}

export function apiEvents(authToken: string) {
  return fetch(`${app_config.apiUrl}/event`, {
    headers: getHeaders(authToken),
  })
}

export function apiReleases(authToken: string, eventId: string, assortment: string) {
  return fetch(`${app_config.apiUrl}/event/${eventId}/releases/${assortment}`, {
    headers: getHeaders(authToken),
  })
}

export function apiVmUrl(
  authToken: string,
  data: {
    products: {
      upc: string
      code: string
      name: string
      colorCode: string
      thumbnailUrl: string
      brand: {
        name: string
        logoUrl: string
      }
    }[]
  },
  customerCountry: string,
) {
  return fetch(`${app_config.apiUrl}/products/vm-url?country=${customerCountry}`, {
    headers: {
      ...getHeaders(authToken),
      'Content-type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  })
}

// ------ TODO #1 ------

class API {
  commonHeaders: Record<string, string>

  constructor(authToken: string, lang: string) {
    this.commonHeaders = getHeaders(authToken, lang)
  }
}

export class DoorAPI extends API {
  baseUrl: string

  constructor(authToken: string, lang: string) {
    super(authToken, lang)
    this.baseUrl = `${app_config.apiUrl}/customer`
  }

  getDoors(authToken: string, customerId: string, eventId: string) {
    const url = `${this.baseUrl}/${customerId}/${eventId}/doors`
    return fetch(url, { headers: { ...this.commonHeaders, ...getHeaders(authToken) } })
  }

  setDoors(authToken: string, customerId: string, eventId: string, doorsMap: unknown) {
    return fetch(`${this.baseUrl}/${customerId}/${eventId}/doors`, {
      headers: {
        ...getHeaders(authToken),
        'Content-type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(doorsMap),
    })
  }
}

export class CartAPI extends API {
  baseUrl: string

  constructor(authToken: string, lang: string) {
    super(authToken, lang)
    this.baseUrl = `${app_config.apiUrl}/cart`
  }

  update(customerId: string, eventId: string, items: unknown[]) {
    const url = `${this.baseUrl}/items`
    const device = getDeviceFromUrl()
    const payload = {
      items,
      customerId,
      eventId,
      room: getRoomFromUrl(),
      device,
    }
    return fetch(url, {
      headers: {
        ...this.commonHeaders,
        'Content-type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(payload),
    }).then(handleErrors)
  }

  getFromOrigin(
    customerId: string,
    eventId: string,
    timestamp: number | undefined,
    forceFullCart: boolean,
  ) {
    const url = `${this.baseUrl}/origin/customer/${customerId}/event/${eventId}${
      timestamp ? `/${timestamp}` : ''
    }?forceFullCart=${forceFullCart.toString()}`
    return fetch(url, {
      headers: {
        ...this.commonHeaders,
        'Content-type': 'application/json',
      },
      method: 'GET',
    }).then(handleErrors)
  }

  getFromCache(customerId: string, eventId: string) {
    const url = `${this.baseUrl}/cache/customer/${customerId}/event/${eventId}`
    return fetch(url, {
      headers: {
        ...this.commonHeaders,
        'Content-type': 'application/json',
      },
      method: 'GET',
    }).then(handleErrors)
  }
}

export class FacetsAPI extends API {
  baseUrl: string
  customerId: string
  eventId: string

  constructor(authToken: string, lang: string, customerId: string, eventId: string) {
    super(authToken, lang)
    this.baseUrl = `${app_config.apiUrl}/facets`
    this.customerId = customerId
    this.eventId = eventId
  }

  facets(activeFiltersAsQueryString: string) {
    const url = `${this.baseUrl}/event/${this.eventId}/customer/${this.customerId}/${activeFiltersAsQueryString}`
    return fetch(url, {
      method: 'GET',
      headers: this.commonHeaders,
    })
  }
}

export class ContentAPI {
  commonHeaders: Record<string, string>
  baseUrl: string

  constructor() {
    this.commonHeaders = {
      'Content-type': 'application/json',
    }
    this.baseUrl = `${app_config.apiUrl}/content`
  }

  get(queryParams: {
    type: string
    eventId?: string
    mocos?: string[]
    cLensAndMocos?: string[]
    model?: string | null
    brand?: string
    cmsTag?: string
    lang?: string
  }) {
    const { type } = queryParams
    const params = qs.stringify({
      ...queryParams,
      cLensAndMocos: queryParams.cLensAndMocos?.join(','),
      mocos: queryParams.mocos?.join(','),
    })
    const url = `${this.baseUrl}/type/${type}?${params}`
    return fetch(url, {
      headers: this.commonHeaders,
    })
  }

  getSeeThroughWithout(queryParams: Record<string, unknown>) {
    const params = qs.stringify(queryParams)
    const url = `${this.baseUrl}/seethroughwithout?${params}`
    return fetch(url, {
      headers: this.commonHeaders,
    })
  }

  getDigitalEventsBackgrounds(queryParams: {
    cmsTag: string
    language: string
    country: string
  }): Promise<ContentDigitalEventsBackgrounds> {
    const params = qs.stringify(queryParams)

    const url = `${this.baseUrl}/digital-events/backgrounds?${params}`
    return fetch(url, {
      headers: this.commonHeaders,
    }).then(handleErrors)
  }

  getAppointmentTilesBackground(queryParams: {
    country: string
    language: string
    cmsTag: string
  }): Promise<ContentDigitalEventsAppointmentTilesBackground> {
    const params = qs.stringify(queryParams)

    const url = `${this.baseUrl}/digital-events/appointment-tile?${params}`
    return fetch(url, {
      headers: this.commonHeaders,
    }).then(handleErrors)
  }

  getInitiatives(queryParams: {
    country: string
    language: string
    cmsTag: string
  }): Promise<Initiative[]> {
    const params = qs.stringify(queryParams)

    const url = `${this.baseUrl}/digital-events/initiatives?${params}`
    return fetch(url, {
      headers: this.commonHeaders,
    }).then(handleErrors)
  }

  getSingleBrandPageContents(queryParams: {
    country: string
    language: string
    brandCode: string
    cmsTag: string
  }): Promise<SingleBrandPageContents> {
    const params = qs.stringify(queryParams)

    const url = `${this.baseUrl}/digital-events/single-brand?${params}`
    return fetch(url, {
      headers: this.commonHeaders,
    }).then(handleErrors)
  }
}

export class CustomerPricePrefsAPI extends API {
  baseUrl: string

  constructor(authToken: string, lang: string, customerId: string, eventId: string) {
    super(authToken, lang)
    this.baseUrl = `${app_config.apiUrl}/customer/${encodeURIComponent(
      customerId,
    )}/price-prefs?eventId=${encodeURIComponent(eventId)}`
  }

  get() {
    return fetch(this.baseUrl, {
      headers: {
        ...this.commonHeaders,
        'Content-type': 'application/json',
      },
      method: 'GET',
    })
  }

  set(pricePrefs: unknown) {
    const url = this.baseUrl

    return fetch(url, {
      method: 'POST',
      headers: {
        ...this.commonHeaders,
        'Content-type': 'application/json',
      },
      body: JSON.stringify(pricePrefs),
    })
  }
}

export function getAllCouvettes(authToken: string, lang: string, queryParams: QueryParams) {
  const params = qs.stringify(queryParams)
  return fetch(`${app_config.apiUrl}/products?${params}`, {
    headers: getHeaders(authToken, lang),
  })
}

export function getAllCouvettesByPost(authToken: string, lang: string, queryParams: QueryParams) {
  return fetch(`${app_config.apiUrl}/products`, {
    headers: {
      ...getHeaders(authToken, lang),
      'Content-type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(queryParams),
  })
}

export function getCouvettesByModelCodes({
  authToken,
  lang,
  customerId,
  eventId,
  modelCodes,
  withContent = false,
  requestType,
  customerType,
}: {
  authToken: string
  lang: string
  modelCodes: string[]
  withContent?: boolean
  requestType: string
  customerId: string
  eventId: string
  customerType: CustomerType
}): Promise<Product[]> {
  const url = `${
    app_config.apiUrl
  }/products/customerId/${customerId}/eventId/${eventId}?modelCodes=${modelCodes.join(
    ',',
  )}&withContent=${withContent}&requestType=${requestType}&customerType=${customerType}&catalog=WHOLESALE`

  return fetch(url, {
    method: 'GET',
    headers: getHeaders(authToken, lang),
  }).then(handleErrors)
}

export class DislikeApi extends API {
  baseUrl: string

  constructor(authToken: string, lang: string) {
    super(authToken, lang)
    this.baseUrl = `${app_config.apiUrl}/dislike`
  }

  toggle(payload: unknown) {
    const url = this.baseUrl
    return fetch(url, {
      headers: {
        ...this.commonHeaders,
        'Content-type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(payload),
    }).then(handleErrors)
  }
}

export class WishlistApi extends API {
  baseUrl: string

  constructor(authToken: string, lang: string) {
    super(authToken, lang)
    this.baseUrl = `${app_config.apiUrl}/wishlist`
  }

  get({ customerId, eventId }: { customerId: string; eventId: string }) {
    if (!customerId || !eventId) return Promise.resolve()
    return fetch(`${this.baseUrl}/customer/${customerId}/event/${eventId}`, {
      headers: this.commonHeaders,
    }).then(handleErrors)
  }

  toggle(payload: {
    wcsToken: string | null
    lang: AppLangCode
    customerId: string
    eventId: string
    products: {
      modelCode: string
      colorCode: string
      brandCode: string
      upc: string
      mocoCode: string
      categoryId: string
      size: string
      skuCode: string
    }[]
    room: string | undefined
    device: string | undefined
  }) {
    const url = this.baseUrl

    return fetch(url, {
      headers: {
        ...this.commonHeaders,
        'Content-type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(payload),
    })
      .then(handleErrors)
      .then(response => {
        response.warnings && showCartWarnings(response.warnings)
        return response
      })
  }
}

export class RecommendationAPI extends API {
  baseUrl: string

  constructor(authToken: string, lang: string) {
    super(authToken, lang)
    this.baseUrl = `${app_config.apiUrl}/recommendation`
  }

  get({
    customerId,
    eventId,
    brand,
    customerType,
    category,
    brandParent,
  }: {
    customerId: string
    eventId: string
    brand: string
    customerType: CustomerType
    category: string
    brandParent: string
  }): Promise<TagGroup> {
    if (!customerId || !eventId) return Promise.resolve(emptyTagGroup)
    const url = `${this.baseUrl}?customerId=${customerId}&eventId=${eventId}&brand=${brand}&customerType=${customerType}&category=${category}&brandParentCode=${brandParent}`
    return fetch(url, {
      method: 'GET',
      headers: this.commonHeaders,
    }).then(handleErrors)
  }
}

export const getAppointmentsApi = ({
  eventId,
  customerId,
  startDate,
  endDate,
  authToken,
  language,
}: {
  eventId: string
  customerId: string
  startDate?: string
  endDate?: string
  authToken: string
  language: string
}): Promise<AppointmentApi[]> => {
  const params = qs.stringify({
    customerId,
    startDate,
    endDate,
  })
  return fetch(`${app_config.apiUrl}/appointments/event/${eventId}?${params}`, {
    headers: getHeaders(authToken, language),
  }).then(handleErrors)
}

export const JoinAppointmentApi = ({
  authToken,
  language,
  appointmentId,
  contactId,
}: {
  authToken: string
  language: string
  appointmentId: number
  contactId: string
}): Promise<JoinAppointmentResponse> => {
  return fetch(
    `${app_config.apiUrl}/appointments/${appointmentId.toString()}/contact/${contactId.toString()}`,
    {
      headers: getHeaders(authToken, language),
      method: 'PUT',
      body: JSON.stringify({}),
    },
  ).then(handleErrors)
}

export const getStarsAssortment = (
  eventId: string,
  customerId: string,
  authToken: string,
  language: string,
  queryParams: Omit<QueryParams, 'customerId' | 'eventId' | 'customerType' | 'catalog'>,
): Promise<StarsResponse> => {
  const params = qs.stringify(queryParams)
  return fetch(
    `${app_config.apiUrl}/assortment/stars/customer/${customerId}/event/${eventId}?${params}`,
    {
      headers: getHeaders(authToken, language),
    },
  ).then(handleErrors)
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  currencySelector,
  priceOpticalSelector,
  priceSunSelector,
  totalOpticalSelector,
  totalSunSelector,
} from '../../../store/cart/selectors'
import { getFluidFontSize } from '../../../style/theme'
import { customerNationalitySelector } from '../../../store/customer/selectors'
import { localesUtils } from '../../../libs/locales'

const Table = styled.table`
  width: 100%;
  text-align: right;
  font-size: ${getFluidFontSize('12px')};
`

const SubtotalRow = styled.tr`
  color: #c4316e;

  &.padded td {
    padding-bottom: 0.5vh;
  }
`

const TotalRow = styled.tr`
  color: #173e5c;
  border-top: 1px solid #d3d3d3;
  td {
    padding-top: 0.5vh;
  }
`

export const CartSummary: React.FC<{
  className?: string
}> = ({ className }) => {
  const { t } = useTranslation()
  const currency = useSelector(currencySelector)
  const priceOptical = useSelector(priceOpticalSelector)
  const priceSun = useSelector(priceSunSelector)
  const totalOptical = useSelector(totalOpticalSelector)
  const totalSun = useSelector(totalSunSelector)
  const customerNationality = useSelector(customerNationalitySelector)

  const totalItems = totalSun + totalOptical
  const priceTotal = priceSun + priceOptical

  return (
    <Table className={className}>
      <tbody>
        <SubtotalRow>
          <td>{t('GenericWords.sun')}</td>
          <td>{`${totalSun} ${t('GenericWords.units')}`}</td>
          <td>{localesUtils.formatCurrency(customerNationality, priceSun, currency)}</td>
        </SubtotalRow>
        <SubtotalRow>
          <td>{t('GenericWords.optical')}</td>
          <td>{`${totalOptical} ${t('GenericWords.units')}`}</td>
          <td>{localesUtils.formatCurrency(customerNationality, priceOptical, currency)}</td>
        </SubtotalRow>
        <TotalRow>
          <td className="bold">{t('CartPage.items_totalorder')}</td>
          <td>{`${totalItems} ${t('GenericWords.units')}`}</td>
          <td className="bold">
            {localesUtils.formatCurrency(customerNationality, priceTotal, currency)}
          </td>
        </TotalRow>
      </tbody>
    </Table>
  )
}

export default CartSummary

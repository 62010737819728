import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import BackgroundVideo from '../../components/BackgroundVideo'
import DigitalEventsFooter from '../../components/DigitalEventsFooter/DigitalEventsFooter'
import DigitalEventsHeader from '../../components/DigitalEventsHeader/DigitalEventsHeader'
import Loading from '../../components/Loading'
import useFetchBackgrounds from '../../hooks/useFetchBackgrounds'
import { Brand } from '../../model/brand'
import { customerIdSelector, eventIdSelector, isKamUserSelector } from '../../store/app/selectors'
import { getBrands } from '../../store/brands/actions'
import { brandsLoadedSelector, brandsSelector } from '../../store/brands/selectors'
import { brandsBackgroundSelector } from '../../store/digitalEvents/selectors'
import {
  MainFullBleed,
  PageWrapper,
  ScrollContainer,
} from '../../style/DigitalEventsCommonComponents'
import { breakpoints, palette, pxToRem, spacing } from '../../style/theme'
import BrandsCard from './components/BrandsCard'

const Container = styled(MainFullBleed)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 500px;

  .swiper-container {
    width: 100%;
    height: 100%;
    perspective: 2000px;
    padding: ${pxToRem(spacing(4))}rem 0;

    @media (max-width: ${breakpoints.M}) {
      height: 75%;
    }

    @media (max-width: ${breakpoints.S}) {
      height: 100%;
      padding: 0;
    }

    @media (max-height: 768px) {
      height: 100%;
      padding: 0;
    }
  }

  .swiper-slide {
    transition: all 0.2s;
    padding: ${pxToRem(spacing(4))}rem ${pxToRem(spacing(1))}rem;

    @media (max-width: ${breakpoints.M}) {
      padding: ${pxToRem(spacing(4))}rem 0;
    }
  }

  .swiper-wrapper {
    transform-style: preserve-3d;
  }

  .swiper-slide[data-position='left-2'] {
    transform: rotate3d(0, 1, 0, 15deg) translate3d(-10px, 0, 200px) scale3d(1, 1, 1);
  }

  .swiper-slide[data-position='left-1'] {
    transform: rotate3d(0, 1, 0, 10deg) translate3d(0px, 0, 50px) scale3d(1, 1, 1);
  }

  .swiper-slide[data-position='right-1'] {
    transform: rotate3d(0, 1, 0, -10deg) translate3d(0px, 0, 50px) scale3d(1, 1, 1);
  }

  .swiper-slide[data-position='right-2'] {
    transform: rotate3d(0, 1, 0, -15deg) translate3d(10px, 0, 200px) scale3d(1, 1, 1);
  }
`

const WrapperPagination = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  justify-content: center;
  color: ${palette.white};
  padding: ${pxToRem(spacing(2))}rem 0;
`

const ButtonPagination = styled.button<{ active?: boolean }>`
  border-radius: 50%;
  width: 28px;
  height: 28px;

  ${({ active }) =>
    active &&
    `
    box-shadow: 20px 12px 110px 0 rgba(107, 89, 114, 0.24);
    background-color: rgba(255, 255, 255, 0.2);
  `}
`

const addSlidesPosition = (swiper: SwiperCore) => {
  const activeIndex = swiper.activeIndex
  swiper.slides.forEach((slide: any, i: any) => {
    const index = activeIndex - i
    slide.dataset.position = index === 0 ? '' : `${index > 0 ? 'left' : 'right'}-${Math.abs(index)}`
  })
}

interface ScrollBrands {
  letter: string
  pos: number
}

const DigitalEventsBrandsPage: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory<{ letter: string }>()
  const background = useSelector(brandsBackgroundSelector)
  const brands = useSelector(brandsSelector)
  const brandsLoaded = useSelector(brandsLoadedSelector)
  const isKamUser = useSelector(isKamUserSelector)
  const [initiativesSwiper, setInitiativeSwiper] = useState<SwiperCore>()
  const [activeButton, setActiveButton] = useState<number>(0)
  const customerId = useSelector(customerIdSelector)
  const eventId = useSelector(eventIdSelector)
  const location = useLocation()
  const currentIndex = new URLSearchParams(location.search).get('index')

  useFetchBackgrounds()

  const firstEnabledBrand = brands.findIndex(({ enabled }) => enabled)

  const slideTo = useCallback(
    (position: number) => {
      if (!!initiativesSwiper?.params && !!initiativesSwiper.params.speed) {
        setTimeout(() => {
          initiativesSwiper.slideTo(position)
          if (activeButton !== position) {
            setActiveButton(oldPosition => (oldPosition === position ? oldPosition : position))
          }
        }, 0)
      }
    },
    [initiativesSwiper, activeButton],
  )

  useEffect(() => {
    if (brandsLoaded && currentIndex && activeButton !== +currentIndex) {
      slideTo(+currentIndex)
    }

    if (brandsLoaded && !currentIndex && firstEnabledBrand !== -1 && !isKamUser) {
      slideTo(firstEnabledBrand)
    }
  }, [currentIndex, brandsLoaded, firstEnabledBrand, isKamUser, slideTo, activeButton])

  useEffect(() => {
    if (customerId && eventId) {
      dispatch(getBrands(customerId, eventId))
    }
  }, [dispatch, customerId, eventId])

  const paginationLetters = (brands: Brand[]) => {
    const arr = brands.map(brand => brand.slug.charAt(0).toUpperCase())
    const brandsPos: ScrollBrands[] = []
    const controller: string[] = []

    arr.map((letter, index) => {
      if (!controller.includes(letter)) {
        brandsPos.push({ letter, pos: index })
        controller.push(letter)
      }
    })
    return brandsPos
  }

  return (
    <PageWrapper background={background.image}>
      <BackgroundVideo background={background.video} />
      <ScrollContainer>
        <DigitalEventsHeader currentPage="brands" />
        <Container>
          {!brandsLoaded ? (
            <Loading />
          ) : (
            <>
              <Swiper
                onSwiper={swiper => {
                  setInitiativeSwiper(swiper)
                  swiper.on('activeIndexChange', addSlidesPosition)
                }}
                slidesPerView={4}
                spaceBetween={30}
                centeredSlides={true}
                onSlideChange={swiper => {
                  history.replace({
                    pathname: '/digital-events/brands',
                    search: '?index=' + swiper.activeIndex,
                  })
                }}
              >
                {brands.map(brand => (
                  <SwiperSlide key={brand.code}>
                    <BrandsCard
                      slug={brand.slug}
                      brandCode={brand.code}
                      active={isKamUser || brand.enabled}
                      background={brand.tileBackground}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <WrapperPagination>
                {paginationLetters(brands).map((value, index) => (
                  <ButtonPagination
                    key={index}
                    active={activeButton === value.pos}
                    onClick={() => {
                      history.replace({
                        pathname: '/digital-events/brands',
                        search: '?index=' + value.pos,
                      })
                    }}
                  >
                    {value.letter}
                  </ButtonPagination>
                ))}
              </WrapperPagination>
            </>
          )}
        </Container>
        <DigitalEventsFooter />
      </ScrollContainer>
    </PageWrapper>
  )
}

export default DigitalEventsBrandsPage

export const convertStringToNumberOrNull = (value: string | null | undefined): number | null => {
  if (!value) {
    return null
  }

  const floatValue = Number(value)

  return !isNaN(floatValue) ? floatValue : null
}

export const isNumber = (value: any) => typeof value === 'number' && !isNaN(value)

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import SwiperCore from 'swiper/core'

import { CollectionHighlightProduct, VideoHighlight } from '../../model/brand'
import { HTMLVideoElementWithDisablePip } from '../../model/model'
import { languageSelector } from '../../store/app/selectors'
import {
  maxHeight,
  OnDisplayTitle,
  scrollMaxHeight,
  XSHeight,
} from '../../style/DigitalEventsCommonComponents'
import { DigitalEventsBoxBorderRadius, palette, pxToRem, spacing } from '../../style/theme'
import { hideSubtitles, isSubtitleHidden } from './helper'
import VideoOverlay from './VideoOverlay'

const VideoWrapper = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding-right: ${pxToRem(spacing(8))}rem;
  padding-left: ${pxToRem(spacing(8))}rem;

  @media (max-height: ${maxHeight}) {
    padding-right: ${pxToRem(spacing(2))}rem;
    padding-left: ${pxToRem(spacing(6))}rem;
    padding-top: 0;
  }

  @media (max-height: ${XSHeight}) {
    padding-left: ${pxToRem(spacing(2))}rem;
    padding-right: ${pxToRem(spacing(2))}rem;
    padding-top: 0;
  }

  @media (max-height: ${scrollMaxHeight}) {
    min-height: 200px;
  }
`

const FlexVideoContainer = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
`

const VideoItem = styled.video`
  background-color: ${palette.black};
  border: none;
  height: auto;
  height: 100%;
  width: 100%;
  border-radius: ${DigitalEventsBoxBorderRadius};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`

type Props = {
  videoRef: React.RefObject<HTMLVideoElementWithDisablePip>
  videoEnded: boolean
  setVideoEnded: React.Dispatch<React.SetStateAction<boolean>>
  maximumTimeReached: React.MutableRefObject<number>
  videoData: VideoHighlight
  currentInDisplayIndex: React.MutableRefObject<number>
  swiperRef: SwiperCore | undefined
  setCurrentProduct: React.Dispatch<React.SetStateAction<CollectionHighlightProduct | null>>
  currentVideoIndex: number
  videos: VideoHighlight[]
  setCurrentVideoIndex: React.Dispatch<React.SetStateAction<number>>
  setCurrentProductVariant: React.Dispatch<React.SetStateAction<string | null>>
  setVideoTime: (milliseconds: number) => void
}

const VideoBox: React.FC<Props> = ({
  videoRef,
  videoEnded,
  setVideoEnded,
  maximumTimeReached,
  videoData,
  currentInDisplayIndex,
  swiperRef,
  setCurrentProduct,
  currentVideoIndex,
  videos,
  setCurrentVideoIndex,
  setCurrentProductVariant,
  setVideoTime,
}) => {
  const { t } = useTranslation()

  const lang = useSelector(languageSelector)

  const registerListener = () => {
    if (videoRef.current) {
      videoRef.current.disablePictureInPicture = true
      videoRef.current.addEventListener('timeupdate', (event: any) => {
        if (event.target.duration - event.target.currentTime === 0) {
          setVideoEnded(true)
        } else if (videoEnded) {
          setVideoEnded(false)
        }

        if (event.target.currentTime * 1000 > maximumTimeReached.current) {
          maximumTimeReached.current = event.target.currentTime * 1000
        }

        const seenProducts =
          videoData?.timeline.filter(
            timelineElements => timelineElements.milliseconds <= event.target.currentTime * 1000,
          ) || []

        const index = seenProducts.length + +!!videoData?.initialItem - 1

        if (currentInDisplayIndex.current !== index) {
          currentInDisplayIndex.current = index
          swiperRef?.slideTo(currentInDisplayIndex.current)
        }

        setCurrentProduct(
          seenProducts.length ? seenProducts[seenProducts.length - 1].product : null,
        )
      })

      const tracks = videoRef.current.textTracks
      if (tracks) {
        tracks.addEventListener(
          'change',
          () => {
            const hasSubtitles = Array.from(tracks).find(track => track.mode !== 'disabled')
            hideSubtitles(!hasSubtitles)
          },
          false,
        )
      }
    }
  }

  const selectedTrack = videoData.subtitles.find(subtitle =>
    subtitle.title.toLocaleLowerCase().endsWith(lang.toLocaleLowerCase()),
  )
    ? videoData.subtitles.find(subtitle =>
        subtitle.title.toLocaleLowerCase().endsWith(lang.toLocaleLowerCase()),
      )?.id
    : videoData.subtitles.find(subtitle => subtitle.language === 'en')?.id

  const otherVideos = videos.slice(currentVideoIndex, 1)

  return (
    <VideoWrapper>
      <OnDisplayTitle>{t('Highlight.video_title')}</OnDisplayTitle>

      <FlexVideoContainer>
        <React.Fragment key={videoData.url}>
          <VideoItem
            ref={videoRef}
            id="video"
            onCanPlay={registerListener}
            crossOrigin="anonymous"
            controls
            preload="metadata"
            disablePictureInPicture
            controlsList="nodownload"
          >
            <source src={videoData.url} type={videoData.mediaType} />
            {videoData.subtitles.map(subtitle => (
              <track
                key={subtitle.id}
                label={subtitle.display}
                kind="subtitles"
                srcLang={subtitle.language}
                src={subtitle.path}
                default={isSubtitleHidden() ? false : subtitle.id === selectedTrack}
              />
            ))}
          </VideoItem>
        </React.Fragment>

        <VideoOverlay
          items={otherVideos}
          visible={videoEnded}
          onClick={id => {
            const realIndex = videos.findIndex(video => video.id === id)
            setCurrentProduct(videos[realIndex] ? videos[realIndex].initialItem : null)
            setVideoEnded(false)
            setCurrentVideoIndex(realIndex)
            setCurrentProductVariant(null)
            setVideoTime(0)
            maximumTimeReached.current = 0
          }}
        />
      </FlexVideoContainer>
    </VideoWrapper>
  )
}

export default VideoBox

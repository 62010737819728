import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized'
import styled from 'styled-components'
import { isMocoInCart } from '../../../libs/cart'
import { getSingleProductHeight } from '../../../libs/couvettes'
import { Brand } from '../../../model/brand'
import { CartProduct, MocoInCart } from '../../../model/cart'
import { currencySelector } from '../../../store/cart/selectors'
import {
  customerNationalitySelector,
  showCartTotalSelector,
} from '../../../store/customer/selectors'
import { cartModeSelector, starsAssortmentSelector } from '../../../store/stars/selectors'
import { CartContent, CartHeader, HeaderTitle } from '../../CommonComponents'
import CouvetteItemV2 from '../../Couvettes/Couvette/CouvetteItem'
import CouvetteItemNonInAssortment from '../../Couvettes/Couvette/CouvetteItem/CouvetteItemNonInAssortment'
import { localesUtils } from '../../../libs/locales'

const StyledCartContent = styled(CartContent)<{ maxImageHeight: number }>`
  overflow: hidden;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
Wrapper.displayName = 'TableCartGrid'

const Subtotals = styled.div`
  font-weight: bold;
  font-size: 0.8rem;
  span {
    white-space: pre-line;
  }
`

export const TableCartGrid: React.FC<{
  mocosInCart: (MocoInCart | CartProduct)[]
  category: string
  cartProducts?: CartProduct[]
  activeBrand?: string | null
  brands: {
    numberOfItems?: number
    brand: Brand
    numberOfItemsByCategory?: { [key: string]: number }
    amountByCategory?: { [key: string]: number }
    amount?: number
  }[]
  className?: string
}> = ({ mocosInCart, category, cartProducts, brands, activeBrand, className }) => {
  const showSubtotals = useSelector(showCartTotalSelector)
  const currency = useSelector(currencySelector)
  const starsAssortment = useSelector(starsAssortmentSelector)
  const mode = useSelector(cartModeSelector)
  const customerNationality = useSelector(customerNationalitySelector)

  const starsUpcs = Object.values(starsAssortment).flatMap(({ upcs }) =>
    Object.values(upcs).map(({ upc }) => upc),
  )

  const { t } = useTranslation()
  const brand = brands.find(({ brand }) => activeBrand === brand.code)
  return (
    <Wrapper className={[`cart-${category}-wrapper`, className].join(' ')}>
      <CartHeader className={mode === 'stars' ? 'stars' : ''}>
        <Subtotals>
          {mode === 'units' &&
            showSubtotals &&
            brand &&
            brand?.numberOfItemsByCategory &&
            brand?.amountByCategory && (
              <span className="subtotals">
                {`${brand?.numberOfItemsByCategory[category]} ${t('GenericWords.units')}
                ${localesUtils.formatCurrency(
                  customerNationality,
                  brand?.amountByCategory[category],
                  currency,
                )}`}
              </span>
            )}
        </Subtotals>
        <HeaderTitle className="header-title">{t(category)}</HeaderTitle>
      </CartHeader>
      <StyledCartContent
        maxImageHeight={getSingleProductHeight()}
        data-type={category}
        key={mocosInCart[0] ? mocosInCart[0].modelCode : null}
      >
        <AutoSizer>
          {({ height, width }) => {
            const cache = new CellMeasurerCache({
              defaultWidth: width,
              minWidth: width,
              minHeight: getSingleProductHeight(),
              defaultHeight: getSingleProductHeight(),
              fixedHeight: false,
              fixedWidth: true,
            })
            return (
              <List
                rowCount={mocosInCart.length}
                width={width}
                height={height}
                estimatedRowSize={getSingleProductHeight()}
                overscanRowCount={4}
                rowHeight={cache.rowHeight}
                rowRenderer={({ index, style, parent, key }) => {
                  const mocoInCart = mocosInCart[index]
                  return (
                    <CellMeasurer
                      cache={cache}
                      columnIndex={0}
                      key={key}
                      parent={parent}
                      rowIndex={index}
                    >
                      {isMocoInCart(mocoInCart) ? (
                        <CouvetteItemV2
                          starsUpcs={starsUpcs}
                          style={style}
                          key={mocoInCart.mocoCode}
                          moco={mocoInCart}
                          cartItems={cartProducts}
                          deleteIcon={true}
                          showWishlist={false}
                          onCartPage={true}
                        />
                      ) : (
                        <CouvetteItemNonInAssortment
                          cartProduct={mocoInCart}
                          cartProducts={cartProducts}
                          style={style}
                          key={mocoInCart.mocoCode}
                        />
                      )}
                    </CellMeasurer>
                  )
                }}
              />
            )
          }}
        </AutoSizer>
      </StyledCartContent>
    </Wrapper>
  )
}

export default TableCartGrid

import {
  breakpoints,
  breakpointsCross,
  getFluidSizeWithFullFormula as gF,
  palette,
} from '../../../../style/theme'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components/macro'
import { DataCartSummeryTable } from '../AfaCartSummary'
import { useSelector } from 'react-redux'
import { displayAsSelector } from '../../../../store/afaCartSummary/selectors'
import { useGet4KDevice } from '../../../../hooks/useGet4KDevice'
import { useGetBrandsQuery } from '../../../../services/afa'
import { useGetCartDeliveryDatesQuery } from '../../../../services/afaCart'
import { convertDdMmYyyyToDate } from '../../../../libs/time'
import React, { useEffect, useRef, useState } from 'react'
import { viewportSelector } from '../../../../store/viewport/selectors'
import { horizontalScrollbarCss } from '../../../../components/CommonComponents'
import { customerNationalitySelector } from '../../../../store/customer/selectors'
import { localesUtils } from '../../../../libs/locales'

const scrollbarHeight = 6 //px

const CellStyle = css`
  padding: 1.25em 2em;
`
const Wrapper = styled.div`
  overflow-y: auto;
  height: 60vh;
  transition: none;

  @media (max-height: ${breakpointsCross.S.max}) {
    height: 50vh;
  }
`

const TableWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
`

const UnderTable = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + ${scrollbarHeight}px);
  background-color: white;
  z-index: -1;
  border-left: 1px solid ${palette.mischka};
  border-bottom: 1px solid ${palette.mischka};
`

const SecondTableWrapper = styled.div`
  overflow: auto;
  flex-basis: 100%;
  ${horizontalScrollbarCss};
  &::-webkit-scrollbar {
    height: ${scrollbarHeight}px;
  }

  @media screen and (min-width: 1367px) and (max-width: 3839px) {
    //In all viewports between iPad and 4k scrollbar is always visible
    color: ${palette.congressBlue} !important;
  }
`

const Table = styled.table`
  border-radius: 5px;
  width: 100%;
  white-space: nowrap;
  border-collapse: collapse;
`

const Fade = styled.div`
  position: absolute;
  top: 1px;
  right: 0;
  width: 10%;
  height: calc(100% - 2px);
  background: linear-gradient(to left, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 80%);
  pointer-events: none; /* so the text is still selectable */
  z-index: 1;
`

const FirstTable = styled(Table)`
  box-shadow: ${gF('px', 12.5, 15, 1366, 3840)} 0px 16px -8px rgba(0, 0, 0, 0.15);
  clip-path: inset(0px -15px 0px 0px);
  z-index: 1;
  flex-basis: content;
  margin-bottom: ${scrollbarHeight}px;
  tr {
    border-right: none;
  }

  tbody tr:last-of-type {
    border-bottom: 1px solid white;
  }
`

const SecondTable = styled(Table)<{ minColumnsToShow: number }>`
  tr {
    border-left: none;
  }
  td,
  th {
    width: calc(100% / ${({ minColumnsToShow }) => minColumnsToShow + 2});
  }
`

const Tr = styled.tr`
  text-transform: uppercase;
  background-color: ${palette.white};
  border: 1px solid ${palette.mischka};
  font-size: 1em;
  color: ${palette.cloudBurst};
  text-align: end;
  font-family: GilmerRegular, sans-serif;

  &.all-products {
    font-family: GilmerBold;
  }

  @media (max-width: ${breakpoints.L}) {
    color: ${palette.tangaroa};
  }
`

const Th = styled.th`
  ${CellStyle};
  font-size: 1.1em;
  color: ${palette.santasGray};

  &.fake {
    background-color: white;

    span {
      visibility: hidden;
    }
  }
  font-family: GilmerBold;
`

const Td = styled.td`
  ${CellStyle};

  &.fake {
    background-color: white;

    span {
      visibility: hidden;
    }
  }
`

const hundredPercent = '100%'
const zeroPercent = '0%'

const calculatePercentage = (partialValue: number, value: number) => {
  return Math.round((100 * partialValue) / value) || 0
}

type Props = {
  tableData: DataCartSummeryTable[]
}

const AfaCartSummaryPageTwo: React.FC<Props> = ({ tableData }) => {
  const { t } = useTranslation()
  const deliveriesQuery = useGetCartDeliveryDatesQuery()
  const deliveryDates = [...new Set(deliveriesQuery.data?.map(({ deliveryDate }) => deliveryDate))]

  const displayAs = useSelector(displayAsSelector)
  const customerNationality = useSelector(customerNationalitySelector)

  const totalQuantities = tableData.reduce((sum, type) => sum + (type?.total.quantity || 0), 0)
  const totalValues = tableData.reduce((sum, type) => sum + (type?.total.value || 0), 0)

  const brandsQuery = useGetBrandsQuery()
  const currency = brandsQuery.data?.[0].currency

  const minColumnsToShow = useGet4KDevice() ? 3 : 5
  const fakeColumnsNumber =
    deliveryDates.length < minColumnsToShow ? minColumnsToShow - deliveryDates.length : 0
  const fakeColumns = new Array(fakeColumnsNumber).fill('fake')

  const firstColumnRef = useRef<HTMLTableCellElement>(null)
  const secondColumnRef = useRef<HTMLTableCellElement>(null)
  const tableWrapperRef = useRef<HTMLDivElement>(null)
  const [tableScrollLeft, setTableScrollLeft] = useState(0)
  const tableRef = useRef<HTMLTableElement>(null)
  const [tableWidth, setTableWidth] = useState(tableRef?.current?.offsetWidth || 0)
  const [tableWrapperWidth, setTableWrapperWidth] = useState(
    tableWrapperRef?.current?.offsetWidth || 0,
  )

  const { width: viewportWidth } = useSelector(viewportSelector)

  useEffect(() => {
    if (tableRef.current) {
      setTableWidth(tableRef.current.offsetWidth)
    }
  }, [tableWidth, viewportWidth])

  useEffect(() => {
    if (tableWrapperRef.current) {
      setTableWrapperWidth(tableWrapperRef.current.offsetWidth)
    }
  }, [tableWrapperRef, viewportWidth])

  const calculateTotalsForEachDeliveryDate = (
    deliveryDate: string,
  ): { quantity: number; value: number } => {
    return tableData.reduce(
      (sum, category) => {
        const valueAndQtyObj = category.deliveryDates[deliveryDate]
        if (valueAndQtyObj) {
          sum = {
            quantity: sum.quantity += valueAndQtyObj.quantity,
            value: sum.value += valueAndQtyObj.value,
          }
        }
        return sum
      },
      { quantity: 0, value: 0 } as { quantity: number; value: number },
    )
  }

  const calculateEachCell = (productType: string, index: number) => {
    const currentProductTypeDates = Object.entries(
      tableData.find(category => category.productType === productType)?.deliveryDates || {},
    ).map(([date, { quantity, value }]) => ({
      [date]: { quantity, value },
    }))

    const sortedDates = currentProductTypeDates
      .slice()
      .sort(
        (a, b) =>
          convertDdMmYyyyToDate(Object.keys(a)[0]).getTime() -
          convertDdMmYyyyToDate(Object.keys(b)[0]).getTime(),
      )

    return Object.values(sortedDates[index] || { quantity: 0, value: 0 })[0]
  }

  const offset = 10
  const scrolledToEnd = tableWidth - tableWrapperWidth - tableScrollLeft < offset

  return (
    <Wrapper>
      <TableWrapper>
        <FirstTable>
          <thead>
            <Tr>
              <Th ref={firstColumnRef}>{t('Afa.fC.productType')}</Th>
              <Th ref={secondColumnRef}>{t('GenericWords.total')}</Th>
            </Tr>
            <Tr className="all-products">
              <Td>{t('Afa.allProducts')}</Td>
              <Td>
                {
                  {
                    quantity: totalQuantities.toLocaleString(),
                    'quantity%': hundredPercent,
                    value: localesUtils.formatCurrency(customerNationality, totalValues, currency),
                    'value%': hundredPercent,
                  }[displayAs]
                }
              </Td>
            </Tr>
          </thead>
          <tbody>
            {tableData.map(({ productType, total }) => {
              return (
                <Tr key={productType}>
                  <Td>{productType}</Td>
                  <Td>
                    {
                      {
                        quantity: total.quantity.toLocaleString() || 0,
                        'quantity%':
                          `${calculatePercentage(total.quantity || 0, totalQuantities)}%` ||
                          zeroPercent,
                        value: localesUtils.formatCurrency(
                          customerNationality,
                          total.value || 0,
                          currency,
                        ),
                        'value%': `${calculatePercentage(total.value || 0, totalValues)}%`,
                      }[displayAs]
                    }
                  </Td>
                </Tr>
              )
            })}
          </tbody>
        </FirstTable>
        <SecondTableWrapper
          ref={tableWrapperRef}
          className="allow-scrollbar"
          onScroll={e => setTableScrollLeft(e.currentTarget.scrollLeft)}
        >
          <Fade hidden={scrolledToEnd} />
          <SecondTable ref={tableRef} minColumnsToShow={minColumnsToShow - 3}>
            <thead>
              <Tr>
                {deliveryDates.map(deliveryDate => {
                  return <Th key={deliveryDate}>{deliveryDate}</Th>
                })}
                {fakeColumns.map((_, index) => (
                  <Td className="fake" key={index}>
                    <span>&nbsp;</span>
                  </Td>
                ))}
              </Tr>
              <Tr className="all-products">
                {deliveryDates.map(deliveryDate => {
                  const totalOfDeliveryDate = calculateTotalsForEachDeliveryDate(deliveryDate)

                  return (
                    <Td key={deliveryDate}>
                      {
                        {
                          quantity: totalOfDeliveryDate.quantity?.toString() || 0,
                          'quantity%': `${calculatePercentage(
                            totalOfDeliveryDate.quantity || 0,
                            totalQuantities,
                          )}%`,
                          value: localesUtils.formatCurrency(
                            customerNationality,
                            totalOfDeliveryDate.value,
                            currency,
                          ),
                          'value%': `${calculatePercentage(
                            totalOfDeliveryDate.value || 0,
                            totalValues,
                          )}%`,
                        }[displayAs]
                      }
                    </Td>
                  )
                })}
                {fakeColumns.map((_, index) => (
                  <Td className="fake" key={index}>
                    <span>&nbsp;</span>
                  </Td>
                ))}
              </Tr>
            </thead>
            <tbody>
              {tableData.map(({ productType }) => {
                return (
                  <Tr key={productType}>
                    {deliveryDates.map((deliveryDate, index) => {
                      const currentDate = calculateEachCell(productType, index)

                      return (
                        <Td key={deliveryDate}>
                          {
                            {
                              quantity: currentDate.quantity?.toString() || 0,
                              'quantity%': `${calculatePercentage(
                                currentDate.quantity || 0,
                                totalQuantities,
                              )}%`,
                              value: localesUtils.formatCurrency(
                                customerNationality,
                                currentDate.value,
                                currency,
                              ),
                              'value%': `${calculatePercentage(
                                currentDate.value || 0,
                                totalValues,
                              )}%`,
                            }[displayAs]
                          }
                        </Td>
                      )
                    })}
                    {fakeColumns.map((_, index) => (
                      <Td className="fake" key={index}>
                        <span>&nbsp;</span>
                      </Td>
                    ))}
                  </Tr>
                )
              })}
            </tbody>
          </SecondTable>
        </SecondTableWrapper>
        <UnderTable />
      </TableWrapper>
    </Wrapper>
  )
}

export default AfaCartSummaryPageTwo

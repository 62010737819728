import { breakpoints, palette, pxToRem, spacing } from '../../../../../style/theme'
import styled from 'styled-components/macro'
import { Doughnut } from 'react-chartjs-2'
import { DataCartSummary } from '../../AfaCartSummary'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { displayAsSelector } from '../../../../../store/afaCartSummary/selectors'
import { useGetBrandsQuery } from '../../../../../services/afa'
import { customerNationalitySelector } from '../../../../../store/customer/selectors'
import { localesUtils } from '../../../../../libs/locales'

const GraphCell = styled.div`
  padding: 1em 1em 0.5em;
  display: flex;
  flex-direction: column;
  background-color: ${palette.white};
`
const PieWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -0.4em;
`

const DoughnutAndTotal = styled.div`
  position: relative;
`

const Total = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.value {
    font-size: 80%;
  }
`

const GraphTitle = styled.h2`
  text-transform: uppercase;
  color: ${palette.cloudBurst};
  margin-left: 0.5em;
  font-size: ${pxToRem(32)}rem;
  font-family: GilmerBold;

  @media (max-width: ${breakpoints.L}) {
    font-size: ${pxToRem(18)}rem;
  }
`
const LabelsWrapper = styled.div`
  padding-bottom: ${pxToRem(spacing(2))}rem;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-left: -1em;
`
const Label = styled.span`
  color: ${palette.black};
  margin-right: 1em;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const LeftSideLabel = styled.div`
  margin-right: 1em;
  display: flex;
  align-items: center;

  & span {
    font-size: 0.8em;
    text-transform: capitalize;
    color: ${palette.cloudBurst};
    font-family: GilmerRegular;

    &.avd {
      text-transform: uppercase;
    }
  }
`
const RightSideLabel = styled.span`
  font-size: 0.8em;
  font-family: GilmerBold;
  color: ${palette.cloudBurst};
`
const Dot = styled.span<{ color?: string }>`
  height: ${pxToRem(12)}rem;
  width: ${pxToRem(12)}rem;
  background-color: ${props => props.color};
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5em;
  & strong {
    text-align: end;
  }
  @media (min-width: ${breakpoints.L}) {
    height: ${pxToRem(20)}rem;
    width: ${pxToRem(20)}rem;
  }
`
const DoughnutMod = styled(Doughnut)`
  max-width: 11em;
  max-height: 11em;
  @media (min-width: ${breakpoints.L}) {
    width: 9em;
    height: 9em;
  }
`

const NothingToShow = styled.span`
  text-transform: capitalize;
  font-family: GilmerRegular;
`

type Props = {
  graphData: DataCartSummary
  doughnutColors: string[]
}

const AfaCartSummaryDoughnut: React.FC<Props> = ({ graphData, doughnutColors }) => {
  const { t } = useTranslation()
  const displayAs = useSelector(displayAsSelector)
  const customerNationality = useSelector(customerNationalitySelector)

  const brandsQuery = useGetBrandsQuery()
  const currency = brandsQuery.data?.[0].currency

  const totalQuantities = graphData.data.reduce(
    (partialSum, { quantity }) => partialSum + quantity,
    0,
  )
  const totalValues = graphData.data.reduce((partialSum, { value }) => partialSum + value, 0)

  const calculatePercentage = (partialValue: number, value: number) => {
    return Math.round((100 * partialValue) / value)
  }

  const dataToShow = () => {
    switch (displayAs) {
      case 'quantity':
        return graphData.data.map(({ quantity }) => quantity)
      case 'quantity%':
        return graphData.data.map(({ quantity }) => calculatePercentage(quantity, totalQuantities))
      case 'value':
        return graphData.data.map(({ value }) => value)
      case 'value%':
        return graphData.data.map(({ value }) => calculatePercentage(value, totalValues))
      default:
        return graphData.data.map(({ quantity }) => quantity)
    }
  }

  const doughnutData = {
    labels: graphData.data.map(({ elementKey }) => elementKey),
    datasets: [
      {
        data: graphData.data.length ? dataToShow() : [1],
        backgroundColor: doughnutColors,
      },
    ],
  }

  return (
    <GraphCell>
      <GraphTitle>{t(`Afa.${graphData.categoryKey}`)}</GraphTitle>
      <PieWrapper key={graphData.data.length}>
        <DoughnutAndTotal>
          <DoughnutMod
            key={displayAs}
            data={doughnutData}
            options={{
              radius: '80%',
              cutout: '75%',
              responsive: true,
              maintainAspectRatio: true,
              plugins: {
                legend: {
                  display: false,
                },
              },
              elements: {
                arc: {
                  borderWidth: 0,
                },
              },
            }}
          />
          {!!graphData.data.length && (
            <Total className={displayAs}>
              {displayAs === 'quantity' && totalQuantities}
              {displayAs === 'value' &&
                localesUtils.formatCurrency(customerNationality, totalValues, currency)}
            </Total>
          )}
        </DoughnutAndTotal>
        <LabelsWrapper>
          {graphData.data.length ? (
            doughnutData.labels.map((label, index) => (
              <Label key={label}>
                <LeftSideLabel>
                  <Dot
                    color={
                      doughnutData.datasets[0].backgroundColor[
                        index % doughnutData.datasets[0].backgroundColor.length
                      ]
                    }
                  />
                  <span className={label.toLowerCase().includes('avd') ? 'avd' : ''}>{label}</span>
                </LeftSideLabel>
                {graphData.data.length && (
                  <RightSideLabel>
                    {displayAs === 'value'
                      ? localesUtils.formatCurrency(
                          customerNationality,
                          doughnutData.datasets[0].data[index],
                          currency,
                        )
                      : doughnutData.datasets[0].data[index].toLocaleString()}
                    {(displayAs === 'quantity%' && '%') || (displayAs === 'value%' && '%')}
                  </RightSideLabel>
                )}
              </Label>
            ))
          ) : (
            <NothingToShow>{t('Afa.nothingToShow')}</NothingToShow>
          )}
        </LabelsWrapper>
      </PieWrapper>
    </GraphCell>
  )
}

export default AfaCartSummaryDoughnut

import { useEffect, useState } from 'react'
import { Rnd } from 'react-rnd'
import { ResizeDirection } from 're-resizable'
import { DraggableData, DraggableEvent } from 'react-draggable'
import {
  BoardType,
  ProductSettingsType,
  WhiteboardProductType,
} from '../../../../../../model/whiteboard'
import { convertPercentageToPx, roundNumber } from '../../../../../../libs/whiteboard'

import { DraggableComponent, Icon, Image, Info, Label, Wrapper } from '../style'
import RCIcon from '../../../../../../components/UI/RCIcon'
import useDraggable from '../useDraggable'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import {
  getMultiselectEnabled,
  getSelectedItems,
} from '../../../../../../store/whiteboard/selectors'
import { useSearchParams } from '../../../../../../hooks/useSearchParams'
import useCheckIfImageNeedsProxy from '../../../../../../hooks/useCheckIfImageNeedsProxy'
import noBackground from '../../../../../../components/icons/noBackground.svg'
import { useTranslation } from 'react-i18next'
import { environmentSelector } from '../../../../../../store/app/selectors'
import { useGetBrandsQuery } from '../../../../../../services/afa'
import { localesUtils } from '../../../../../../libs/locales'
import { customerNationalitySelector } from '../../../../../../store/customer/selectors'

type Props = {
  showAlert: boolean
  item: WhiteboardProductType
  boardSettings: Partial<BoardType>
  itemSettings: ProductSettingsType
  updatePosition: () => void
  reference: any //React.MutableRefObject<Rnd | null>
  whiteboardIsLocked: boolean
}

const DraggableItem = ({
  showAlert,
  item,
  itemSettings,
  boardSettings,
  updatePosition,
  reference,
  whiteboardIsLocked,
}: Props) => {
  const { t } = useTranslation()

  const {
    id,
    selected,
    product: {
      productName,
      productNumber,
      colorCode,
      colorName,
      suggestedPrice,
      wholesalePrice,
      invalid,
    },
  } = item

  const {
    showProductName,
    showProductNumber,
    showWholesale,
    showSuggested,
    showColorCode,
    showColorName,
    labelPosition,
  } = boardSettings

  /**
   * x, y: rappresentano le coordinate iniziali dell'elemento selezionato. NON vengono aggiornate durante il d&d. SONO aggiornate alla fine del d&d
   */
  const {
    position: { x, y },
    style: { zIndex, width, height },
  } = itemSettings

  const isTouchDevice = 'ontouchstart' in window

  const {
    handleMouseMove,
    handleResizeStop,
    handleDragStop,
    handleClick,
    handleResizeStart,
    handleDrag,
    handleResize,
  } = useDraggable(item, 'items', x, y, reference)

  const environment = useSelector(environmentSelector)
  const selectedItems = useSelector(getSelectedItems)
  const selectedItemsArray = Object.entries(selectedItems).flatMap(([, values]) => values)
  const multiselectEnabled = useSelector(getMultiselectEnabled)
  const isSingleItemSelection = selected && !multiselectEnabled && selectedItemsArray.length < 2
  const [searchParams, setSearchParams] = useSearchParams()
  const { fetchImage } = useCheckIfImageNeedsProxy()
  const [image, setImage] = useState<string | undefined>(undefined)
  const customerNationality = useSelector(customerNationalitySelector)

  const brandsQuery = useGetBrandsQuery()
  const defaultCurrency = brandsQuery.data?.[0].currency || ''

  useEffect(() => {
    if (reference) {
      reference.current?.updateSize({
        width: convertPercentageToPx(width),
        height: convertPercentageToPx(height),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height])

  useEffect(() => {
    if (reference) {
      reference.current?.updatePosition({
        x: convertPercentageToPx(x),
        y: convertPercentageToPx(y),
      })
    }
  }, [reference, x, y])

  const getImage = (image: string) =>
    image?.indexOf('cdn-record-files-pi/') > -1 ? image.split('cdn-record-files-pi/')[1] : image

  useEffect(() => {
    if (reference) {
      const element = reference.current?.resizableElement.current as HTMLElement
      const { width, height } = element.getBoundingClientRect()

      element.setAttribute('starting-x', `${convertPercentageToPx(x)}`)
      element.setAttribute('starting-y', `${convertPercentageToPx(y)}`)
      element.setAttribute('starting-width', `${roundNumber(width)}`)
      element.setAttribute('starting-height', `${roundNumber(height)}`)
      element.setAttribute('id', `${id}`)
    }
  }, [id, reference, x, y])

  useEffect(() => {
    if (!image) {
      const request = indexedDB.open('rc-whiteboard')
      request.onsuccess = event => {
        const target = event?.target as IDBOpenDBRequest
        const db = target.result
        db
          .transaction(['proxy-images'], 'readwrite')
          .objectStore('proxy-images')
          .getAll().onsuccess = event => {
          const target = event?.target as IDBOpenDBRequest
          const images = (target.result as unknown) as { path: string; base64: string }[]
          const image = images.find(({ path }) => path === item.product.image)

          if (image) {
            setImage(image.base64)
          } else {
            fetchImage(getImage(item.product.image), environment).then(imageUrl => {
              const objectStore = db
                .transaction(['proxy-images'], 'readwrite')
                .objectStore('proxy-images')

              objectStore.add({
                path: item.product.image,
                base64: imageUrl,
              })
              setImage(imageUrl)
            })
          }
        }
      }
    }
  }, [environment, fetchImage, image, item.product.image])

  const board = document.getElementById('board') as HTMLDivElement
  const minWidth = board?.clientWidth * 0.1 || 0

  const props = { enableResizing: !whiteboardIsLocked ? undefined : !whiteboardIsLocked }

  const product = [showProductNumber && productNumber, showColorCode && colorCode]
    .filter(Boolean)
    .join(' / ')

  return reference ? (
    <DraggableComponent
      {...props}
      ref={(c: Rnd | null) => (reference.current = c)}
      style={{
        zIndex,
      }}
      minHeight={`${minWidth}px`}
      minWidth={`${minWidth}px`}
      className={classnames({
        product: true,
        selected,
        isTouchDevice,
        multiselectEnabled,
        isSingleItemSelection,
        whiteboardIsLocked,
      })}
      cancel={!selected ? '.wrapper' : ''}
      resizeHandleWrapperClass="cursor-pointer"
      onMouseMove={handleMouseMove}
      onDrag={(e: DraggableEvent, data: DraggableData) =>
        !whiteboardIsLocked && !multiselectEnabled && handleDrag(e, data)
      }
      onResizeStart={() => {
        const resizing = searchParams.get('resizing')

        if (!resizing) {
          searchParams.set('resizing', 'true')
          setSearchParams(searchParams, { replace: true })
        }

        handleResizeStart()
      }}
      onResizeStop={() => {
        handleResizeStop()
        setTimeout(() => {
          searchParams.delete('resizing')
          setSearchParams(searchParams, { replace: true })
        }, 200)
      }}
      onResize={(e: MouseEvent | TouchEvent, dir: ResizeDirection) => {
        handleResize(dir)
        /*        setCurrentWidth(convertPxToPercentage(elementRef.style.width))
        setCurrentHeight(convertPxToPercentage(elementRef.style.height))*/
      }}
      onDragStop={() => {
        if (whiteboardIsLocked) return
        handleDragStop()
        updatePosition()
      }}
      bounds="parent"
      lockAspectRatio
      default={{
        x: convertPercentageToPx(x),
        y: convertPercentageToPx(y),
        width: convertPercentageToPx(width),
        height: convertPercentageToPx(height),
      }}
    >
      {/*      {process.env.NODE_ENV === 'development' && <HorizontalCenter/>}
      {process.env.NODE_ENV === 'development' && <VerticalCenter/>}*/}
      <Wrapper
        className="wrapper"
        onClick={() => !isTouchDevice && handleClick()}
        onTouchEnd={() => handleClick()}
      >
        <Image
          loading="lazy"
          draggable={false}
          src={item.product.image ? image : noBackground}
          isImageValid={!!item.product.image}
          alt=""
        />
        {showAlert && (
          <Icon>
            <RCIcon type="afa-success" size="100%" />
          </Icon>
        )}
      </Wrapper>
      <Label
        width={convertPercentageToPx(width)}
        height={convertPercentageToPx(height)}
        className={labelPosition}
      >
        {invalid ? (
          <Info className="OOA">{t('Whiteboard.outOfAssortment')}</Info>
        ) : (
          <>
            {showProductName && <Info className="product-name">{productName}</Info>}
            <Info className="product-number">{product}</Info>
            {showColorName && <Info className="color-name">{colorName}</Info>}
            <Info>
              {showWholesale && (
                <Info inline className="wholesale-price">
                  {localesUtils.formatCurrency(
                    customerNationality,
                    wholesalePrice,
                    defaultCurrency,
                  )}
                </Info>
              )}
              {showSuggested && (
                <Info inline className="suggested-price">
                  {' '}
                  /{' '}
                  {localesUtils.formatCurrency(
                    customerNationality,
                    suggestedPrice,
                    defaultCurrency,
                  )}
                </Info>
              )}
            </Info>
          </>
        )}
      </Label>
    </DraggableComponent>
  ) : null
}

export default DraggableItem

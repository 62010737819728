import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Brand as BrandModel } from '../../../model/brand'
import { currencySelector } from '../../../store/cart/selectors'
import { cartModeSelector } from '../../../store/stars/selectors'
import { breakpoints, palette, pxToRem } from '../../../style/theme'
import CartIcon from '../../icons/CartIcon'
import { useGetBrandLogo } from '../../../services/afaContents'
import { customerNationalitySelector } from '../../../store/customer/selectors'
import { localesUtils } from '../../../libs/locales'

const Wrapper = styled.div`
  position: relative;
  width: calc(100% - 10px);
  aspect-ratio: 1.3;
  font-size: 1rem;
  text-align: center;
  color: ${palette.hibiscus};
  background-color: ${palette.white};
  display: grid;
  grid-template-columns: 90%;
  /* for non clamp capable browser */
  font-size: calc(7px + 9 * (100vw / 3840));
  font-size: clamp(${pxToRem(7)}, 0.7vw, ${pxToRem(16)});
  border: 6px solid transparent;

  .price {
    font-weight: bold;
  }

  &.active {
    color: ${palette.curiousBlue};
    border-color: ${palette.curiousBlue};

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 20px solid ${palette.curiousBlue};
      position: absolute;
      left: 100%;
      top: calc(50% - 20px);

      @media screen and (max-width: ${breakpoints.L}) {
        border-top-width: 10px;
        border-bottom-width: 10px;
        border-left-width: 10px;
        top: calc(50% - 10px);
      }
    }

    @media screen and (max-width: ${breakpoints.L}) {
      border-width: 3px;
    }
  }
`

const LogoAndCounters = styled.div`
  display: flex;
  flex-direction: column;
  aspect-ratio: 1.2;

  @media screen and (min-width: ${breakpoints.XL}) {
    justify-content: center;
    margin-top: 1em;
  }
`

const Icons = styled.div`
  display: flex;

  > svg {
    width: 1em;
    height: 1em;
  }

  > *:last-child {
    margin-left: auto;
    order: 2;
  }
`

const LogoWrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  aspect-ratio: 1.5;
`

const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: 'contain';
`

const isVisible = (el: HTMLElement) => {
  const parent = el.parentElement
  const elBox = el.getBoundingClientRect()
  if (!parent) {
    return
  }
  const parentBox = parent.getBoundingClientRect()

  return elBox.top > parentBox.top && elBox.bottom < parentBox.bottom
}

const scrollIntoViewIfActive = (active: boolean, el: HTMLElement | null) => {
  if (active && el && !isVisible(el)) {
    setTimeout(() => {
      el.scrollIntoView({ behavior: 'smooth' })
    }, 500)
  }
}

const TableCartSingleBrand: React.FC<{
  onClickBrand: (b: BrandModel) => void
  brand: BrandModel
  numberOfItems?: number
  active?: boolean
  amount?: number
  showAmounts: boolean
  isCartBrand?: boolean
}> = ({ onClickBrand, brand, numberOfItems, active, amount, showAmounts, isCartBrand }) => {
  const { t } = useTranslation()

  const mode = useSelector(cartModeSelector)
  const currency = useSelector(currencySelector)
  const customerNationality = useSelector(customerNationalitySelector)

  const ref = useRef(null)

  useEffect(() => {
    scrollIntoViewIfActive(!!active, ref.current)
  }, [active])

  const { brandLogo, isSuccess } = useGetBrandLogo(brand.code)

  return (
    <Wrapper
      className={`cart-single-brand ${active ? 'active' : ''}`}
      onClick={() => onClickBrand(brand)}
      id={`brand-${brand.slug}`}
      ref={ref}
    >
      <LogoAndCounters className="cart-single-brand__logoAndCounters">
        <LogoWrapper>
          {brandLogo || !isSuccess ? (
            <Logo src={brandLogo} alt={brand.brand || brand.code} />
          ) : (
            <span>{brand.brand || brand.code}</span>
          )}
        </LogoWrapper>
        {numberOfItems && numberOfItems > 0 && (
          <div className="items">{`${numberOfItems} ${
            mode === 'stars' ? t('GenericWords.items') : t('GenericWords.units')
          }`}</div>
        )}
        {mode === 'units' && showAmounts && amount && amount > 0 && (
          <div className="amount">
            {localesUtils.formatCurrency(customerNationality, amount, currency)}
          </div>
        )}
        <Icons className="cart-single-brand__icons">{isCartBrand && <CartIcon />}</Icons>
      </LogoAndCounters>
    </Wrapper>
  )
}

export default TableCartSingleBrand

import { apiVmUrl, ContentAPI, getAllCouvettes } from '../../api/restApi'
import app_config from '../../config/app/config'
import { getBrandByBrandCode } from '../../libs/brand'
import { checkVirtualMirrorAvailability } from '../../libs/couvettes'
import { getCmsTagFromEventList } from '../../libs/event'
import { handleErrors } from '../../libs/handleError'
import log from '../../libs/log'
import { extractColorCodeFromMocoCode } from '../../libs/productsV2'
import { AppThunk, AppThunkPromise, RootState } from '../../model/model'
import { Product, QueryParams } from '../../model/product'
import { dataLoadedFail } from '../app/actions'
import { customerIdSelector, eventIdSelector } from '../app/selectors'
import { tokenSelector } from '../auth/selectors'
import { subbrandToMainBrandMapSelector } from '../brands/selectors'
import { couvettesSelector } from '../couvettes/selectors'
import { customerNationalitySelector, customerTypeSelector } from '../customer/selectors'
import { hideSearch, resetSearchState } from '../search/actions'
import { isSearchVisibleSelector, modelsFoundSelector } from '../search/selectors'
import { vmHashAllDataSelector, vmHashAllDataSelectorFromModel } from './selectors'
import { slice } from './slice'
import { errorNotification } from '../../components/Notification/notifications'

export function closeVTO(waitUnmount: boolean): AppThunk {
  return dispatch => {
    dispatch(slice.actions.closeVTOView({ waitUnmount }))
  }
}

export function changeSelectedSku(code: string, skuChangedByScrolling = false): AppThunk {
  return (dispatch, getState) => {
    const state = getState()
    dispatch(
      slice.actions.showPDP({
        code: code,
        modelCode: state.pdp.modelCode,
        skuChangedByScrolling: skuChangedByScrolling,
      }),
    )
  }
}

export function searchPdpCouvetteSuccess(couvette: Product): AppThunk {
  return dispatch => {
    dispatch(slice.actions.savePdpInfo({ couvette }))
    checkVirtualMirrorAvailability(couvette.mocos).then(virtualMirrorAvailability => {
      return dispatch(
        slice.actions.searchPdpCouvetteSuccess({
          couvette: couvette,
          virtualMirrorAvailability: virtualMirrorAvailability,
        }),
      )
    })
  }
}

export function switchPdpType(): AppThunk {
  return dispatch => {
    dispatch(slice.actions.switchPdpType())
  }
}

export function closePDP(): AppThunk {
  return dispatch => {
    dispatch(slice.actions.closePdpView())
    dispatch(slice.actions.closeVTOView({ waitUnmount: false }))
    dispatch(slice.actions.setSeethroughModalVisibility(false))
    dispatch(resetSearchState())
  }
}

export function clearPDPModel(): AppThunk {
  return dispatch => {
    dispatch(slice.actions.clearPdpModel())
  }
}

const getCouvetteFromCouvettes = (state: RootState, modelCode: string) => {
  const couvettes: { items: Product[] } = couvettesSelector(state)
  const couvette = couvettes.items.find(couvette => couvette.modelCode === modelCode)

  return couvette
}

export const searchPdpCouvette = ({
  modelCode,
  language,
  colorCode,
}: {
  modelCode: string | null
  language?: string
  colorCode?: string
}): AppThunk => {
  return (dispatch, getState) => {
    dispatch(slice.actions.searchingPdpCouvette())
    const state = getState()

    const customerType = customerTypeSelector(state)

    const queryParams: QueryParams = {
      customerId: customerIdSelector(state),
      eventId: eventIdSelector(state),
      search: modelCode || undefined,
      showAttribute: true,
      customerType,
      catalog: 'WHOLESALE',
    }

    return getAllCouvettes(state.auth.token || '', language || state.app.lang, queryParams)
      .then(handleErrors)
      .then((couvetteData: { items: Product[] }) => {
        const couvette =
          couvetteData && couvetteData.items && couvetteData.items.length
            ? couvetteData.items.find(item => item.modelCode === modelCode)
            : null

        const mocoIsLoaded = colorCode ? !!couvette?.mocos[colorCode] : true
        if (!couvette || !mocoIsLoaded) {
          dispatch(slice.actions.searchPdpCouvetteFail())
          return errorNotification({
            className: 'rc-notification',
            message: 'Sorry, no information for this product. It may be out of catalogue.',
          })
        }
        return dispatch(searchPdpCouvetteSuccess(couvette))
      })
      .catch(() => {
        dispatch(slice.actions.searchPdpCouvetteFail())
      })
  }
}

export const openPDP = (mocoCode: string, modelCode: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch(slice.actions.showPDP({ code: mocoCode, modelCode: modelCode }))

    const state = getState()

    const isSearchVisible = isSearchVisibleSelector(state)

    if (isSearchVisible) {
      const modelsFound = modelsFoundSelector(state)
      if (modelsFound && modelsFound.length && modelsFound[0].modelCode === modelCode) {
        dispatch(searchPdpCouvetteSuccess(modelsFound[0]))
        dispatch(hideSearch())
        dispatch(slice.actions.showPDP({ code: mocoCode, modelCode: modelCode }))
        return
      }
    }

    const model = getCouvetteFromCouvettes(state, modelCode)
    if (model) {
      dispatch(searchPdpCouvetteSuccess(model))
    } else {
      dispatch(
        searchPdpCouvette({
          modelCode,
          colorCode: extractColorCodeFromMocoCode(mocoCode),
        }),
      )
    }
  }
}

export const loadPdpContent = ({
  mocos,
  cLensAndMocos,
  brand,
  lang,
}: {
  mocos: string[]
  cLensAndMocos: string[]
  brand?: string
  lang?: string
}): AppThunk => {
  return (dispatch, getState) => {
    if (!brand) {
      return
    }

    const {
      app,
      pdp: { modelCode },
    } = getState()
    const { eventId, events } = app
    const state = getState()
    const mainBrand = subbrandToMainBrandMapSelector(state)
    const cmsTag = getCmsTagFromEventList(eventId, events)

    dispatch(slice.actions.loadingContent())

    const contentAPI = new ContentAPI()
    contentAPI
      .get({
        type: 'pdp',
        eventId,
        mocos,
        cLensAndMocos,
        model: modelCode,
        brand: mainBrand[brand],
        cmsTag,
        lang: lang || app.lang,
      })
      .then(handleErrors)
      .then(data => dispatch(slice.actions.loadingContentSuccess(data)))
      .catch(() => dispatch(slice.actions.loadingContentFail()))
  }
}

export const getSeeThroughWithoutContent = (
  mocoCode: string,
  brandCode: string,
  lensColor?: string,
): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    const {
      app: { eventId },
      brands,
    } = state
    const brand = getBrandByBrandCode(brands.items, brandCode)
    const mainBrandCode = brand?.code
    // We need to run the api call only for OO subbrands
    if (mainBrandCode !== 'OO') return

    dispatch(slice.actions.setSeethroughWithoutLoading(mocoCode))

    const cmsTag = state.app.events
      ?.filter(event => event.id === eventId)
      .reduce((result, event) => event.cmsTag, undefined as undefined | string)

    const contentAPI = new ContentAPI()
    contentAPI
      .getSeeThroughWithout({
        brand: mainBrandCode,
        cmsTag,
        lensColor,
      })
      .then(handleErrors)
      .then(data => {
        if (data && data.image && data.image.url) {
          dispatch(
            slice.actions.updateSeethroughWithoutContent({ mocoCode: mocoCode, content: data }),
          )
        }
        dispatch(slice.actions.setSeethroughWithoutLoaded(mocoCode))
      })
      .catch(() => {
        dispatch(slice.actions.setSeethroughWithoutLoaded(mocoCode))
        dispatch(slice.actions.loadingContentFail())
      })
  }
}

export const setVmUrlAction = (selectedUpc: string | null, upcs?: string[]): AppThunk => (
  dispatch,
  getState,
) => {
  if (!selectedUpc) {
    return dispatch(slice.actions.setVmUrl(''))
  }

  const state = getState()
  const virtualMirrorProducts = vmHashAllDataSelector(selectedUpc, upcs)(state)
  if (!virtualMirrorProducts) {
    return dispatch(slice.actions.setVmUrl(''))
  }

  const authToken = tokenSelector(state)
  const customerCountry = customerNationalitySelector(state)
  apiVmUrl(
    authToken || '',
    {
      products: virtualMirrorProducts.map(product => {
        const { code, name, colorCode, thumbnailUrl, brandName, brandLogoUrl, upc } = product

        return {
          upc,
          code,
          name,
          colorCode,
          thumbnailUrl,
          brand: {
            name: brandName,
            logoUrl: `${app_config.publicUrl}/assets/images/loghi/${brandLogoUrl}`,
          },
        }
      }),
    },
    customerCountry,
  )
    .then(handleErrors)
    .then(({ url }) => dispatch(slice.actions.setVmUrl(url)))
    .catch(err => {
      log.error(err, 'retrieveCustomerList')
      return dispatch(dataLoadedFail(err))
    })
}

export const getVmUrl = (
  selectedUpc: string,
  upcs: string[],
  model?: Product,
): AppThunkPromise<{ url: string }> => (dispatch, getState) => {
  if (!upcs) return Promise.resolve(null)
  const state = getState()
  const authToken = tokenSelector(state)
  const customerCountry = customerNationalitySelector(state)
  const vtoSizes = vmHashAllDataSelectorFromModel(selectedUpc, upcs, model)(state)
  if (!(vtoSizes && vtoSizes.length)) return Promise.resolve(null)
  return apiVmUrl(
    authToken || '',
    {
      products: vtoSizes.map(product => {
        const { code, name, colorCode, thumbnailUrl, brandName, brandLogoUrl, upc } = product

        return {
          upc,
          code,
          name,
          colorCode,
          thumbnailUrl,
          brand: {
            name: brandName,
            logoUrl: `${app_config.publicUrl}/assets/images/loghi/${brandLogoUrl}`,
          },
        }
      }),
    },
    customerCountry,
  ).then(handleErrors)
}

export const openVTOView = slice.actions.openVTOView
export const showFullscreen360 = slice.actions.showFullscreen360
export const closeFullscreen360 = slice.actions.closeFullscreen360
export const savePdpInfo = slice.actions.savePdpInfo
export const setSeethroughModalVisibility = slice.actions.setSeethroughModalVisibility
export const enable360 = slice.actions.enable360
export const disable360 = slice.actions.disable360
export const toggleAdvancedDoorSelectionAction = slice.actions.toggleAdvancedDoorSelectionAction
export const setSeeThemOnViewType = slice.actions.setSeeThemOnViewType
export const setSeeThemOnCurrentSlide = slice.actions.setSeeThemOnCurrentSlide
export const setSeeThemOnCurrentFacesBlockId = slice.actions.setSeeThemOnCurrentFacesBlockId
export const setSeeThroughSelectedMoco = slice.actions.setSeeThroughSelectedMoco
export const setVirtualMirrorConfig = slice.actions.setVirtualMirrorConfig

const pdpActions = {
  ...slice.actions,
  closeVTO,
  searchPdpCouvette,
  openPDP,
  loadPdpContent,
  getSeeThroughWithoutContent,
  setVmUrlAction,
  getVmUrl,
}

export default pdpActions
